// This contains all javascript that can be called universally from the 'guardian' layout.

// import $ from 'jquery';
//= require dropzone/dist/dropzone.js
//= require plugins/dropzone.js

$(document).on("turbolinks:load", function () {

    $('.dropdown-toggle').dropdown()


    // This is for handling the login button
    // At the moment, necessary because the fade/background 
    // stays on top of the login and you can't click to dismiss it (or enter anything else)
    var loginModal = $("#login-modal");
    $("#login-btn").click(function (e) {
        e.preventDefault(); // prevent the link clickthrough

        loginModal.modal("show"); // show the modal

        setTimeout(function () { // remove the fade/backdrop
            $('.modal-backdrop').remove();
        }, 300);
    });

    // This patch is here because if you navigate to another page
    // the menu button stops responding / showing the dropdown
    var menuBtn = $('#top-menu-dropdown');
    menuBtn.dropdown();
    menuBtn.click(function (e) {
        console.log("menu clicked!");
        e.preventDefault(); // prevent the link clickthrough
        // show the dropdown
        // $('#company_menu').addClass('show')
        // setTimeout(function() { // remove the fade/backdrop
        //   $('.modal-backdrop').remove();
        // }, 300);
    });

});